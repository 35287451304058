import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";

export default function BookingInformation() {
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="left-side-bar">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M19 19L13 13"
                  stroke="#2F2B3D"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <circle
                  cx="8"
                  cy="8"
                  r="7"
                  stroke="#2F2B3D"
                  stroke-opacity="0.9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <input type="tetx" placeholder="Search ⌘K" />
            </div>
            <div className="right-side-bar">
              <div className="user-img-top">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={require("../Assets/Images/user.svg").default} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="">
                      <div className="drop-pro-view">
                        <img
                          src={require("../Assets/Images/user.svg").default}
                        />
                        <div className="admin-name">
                          <h3>John Doe</h3>
                          <p>Admin</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <div className="my-profile">
                      <Dropdown.Item href="my-profile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <ellipse
                            cx="10.9999"
                            cy="6.41667"
                            rx="3.66667"
                            ry="3.66667"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        My Profile
                      </Dropdown.Item>
                    </div>
                    <Dropdown.Divider />

                    <Dropdown.Item href="/" className="logout-btn">
                      Logout
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                          fill="white"
                        />
                      </svg>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container fluid>
        <div className="image-wapper">
          <div className="user-over-view">
            <div className="left-side-over-view">
              <div className="inner-over-box">
                <div className="my-overview-prof">
                  <img
                    src={require("../Assets/Images/my-profile.svg").default}
                  />
                  <h2>Seth Hallam</h2>
                </div>

                <div className="details-heading-text">
                  <h2>Details</h2>
                  <div className="user-full-details">
                    <ul>
                      <li>
                        <p>
                          Username: <span>@shallamb</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Email: <span>shallamb@gmail.com</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Contact: <span>+1 (234) 464-0600</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-over-view">
              <div className="inner-box-images">
                <div className="outer-shadow-box mt-0">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Booking Information</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-grid">
                          <div className="booking-label">
                            <label>Booking ID</label>
                            <p>XYZ123</p>
                          </div>
                          <div className="booking-label">
                            <label>Property Name</label>
                            <p>Alpha Homes</p>
                          </div>

                          <div className="booking-label">
                            <label>Guests </label>
                            <p>2</p>
                          </div>
                          <div className="booking-label">
                            <label>Rooms</label>
                            <p>1</p>
                          </div>

                          <div className="booking-label">
                            <label>Check In Date</label>
                            <p>22-Jan-2023 | 09:30 AM</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="outer-shadow-box">
                  <div className="grid-over-view">
                    <div className="inner-filter-field">
                      <div className="image-heading-text">
                        <h2>Booking Information</h2>
                      </div>
                      <div className="booking-info">
                        <div className="booking-status">
                          <p>Customer Checked In</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
