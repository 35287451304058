import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import Dropdown from "react-bootstrap/Dropdown";
import Pagination from "react-bootstrap/Pagination";
import Orders from "../Components/ChartOrders";
import Users from "../Components/ChartOrders";
import CategoryChart from "../Components/ChartUsers";

export default function Dashboard() {
  return (
    <Layout>
      <Container fluid>
        <div className="dashboard-items mt-3">
          <div className="right-top-bar">
            <div className="left-side-bar">
            
            </div>
            <div className="right-side-bar">
              <div className="notify-icon"></div>
              <div className="user-img-top">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <img src={require("../Assets/Images/user.svg").default} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item className="">
                      <div className="drop-pro-view">
                        <img
                          src={require("../Assets/Images/user.svg").default}
                        />
                        <div className="admin-name">
                          <h3>John Doe</h3>
                          <p>Admin</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <div className="my-profile">
                      <Dropdown.Item href="my-profile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <ellipse
                            cx="10.9999"
                            cy="6.41667"
                            rx="3.66667"
                            ry="3.66667"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.5 19.25V17.4167C5.5 15.3916 7.14162 13.75 9.16667 13.75H12.8333C14.8584 13.75 16.5 15.3916 16.5 17.4167V19.25"
                            stroke="#2F2B3D"
                            stroke-opacity="0.9"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>{" "}
                        My Profile
                      </Dropdown.Item>
                    </div>

                    <Dropdown.Divider />

                    <Dropdown.Item href="/" className="logout-btn">
                      Logout
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M3.41659 12.8333C3.26188 12.8333 3.1135 12.7718 3.00411 12.6624C2.89471 12.553 2.83325 12.4047 2.83325 12.25V1.74996C2.83325 1.59525 2.89471 1.44688 3.00411 1.33748C3.1135 1.22808 3.26188 1.16663 3.41659 1.16663H11.5833C11.738 1.16663 11.8863 1.22808 11.9957 1.33748C12.1051 1.44688 12.1666 1.59525 12.1666 1.74996V3.49996H10.9999V2.33329H3.99992V11.6666H10.9999V10.5H12.1666V12.25C12.1666 12.4047 12.1051 12.553 11.9957 12.6624C11.8863 12.7718 11.738 12.8333 11.5833 12.8333H3.41659ZM10.9999 9.33329V7.58329H6.91659V6.41663H10.9999V4.66663L13.9166 6.99996L10.9999 9.33329Z"
                          fill="white"
                        />
                      </svg>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Container fluid>
        <div className="dashboard-items">
          <Row>
            <Col xxl={12} xl={12} lg={12}>
              <div className="dashbox-inner-wrap">
                <Row>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Users</p>
                          <h2>
                            24
                          </h2>
                          <span className="total-users">
                            Total Average User per day 0
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/session-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Properties LISTED</p>
                          <h2>
                            0
                          </h2>
                          <span className="total-users">
                            Total Hotels Listed listed per day 0
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/padi-user.svg").default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Bookings </p>
                          <h2>
                            0
                          </h2>
                          <span className="total-users">
                            Total Average booking per day 0
                          </span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/active-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xxl={3} xl={3} lg={3}>
                    <div className="dash-inner-boxes">
                      <div className="session">
                        <div className="left-session">
                          <p>Total Revenue</p>
                          <h2>
                          0
                          </h2>
                          <span className="total-users">Last week Revenue</span>
                        </div>
                        <div className="user-icon-se">
                          <img
                            src={
                              require("../Assets/Images/pending-user.svg")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row className="dash-bottom mb-4 mt-4">
            <Col xxl={6} xl={6} lg={6} className="mb-4">
              <div className="dash-graph">
                <h6>Total Users</h6>
                <CategoryChart />
                <div className="bott-order mt-4">
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/green-dot.svg").default}
                      />
                    </span>
                    Active Customers 50%
                  </p>
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/light-green.svg").default
                        }
                      />
                    </span>
                    Active Hotel Owners 50%
                  </p>
                </div>
              </div>
            </Col>

            <Col xxl={6} xl={6} lg={6}>
              <div className="dash-graph ">
                <h6>Total Bookings</h6>
                <CategoryChart />
                <div className="bott-order mt-4">
                  <p>
                    <span>
                      <img
                        src={require("../Assets/Images/green-dot.svg").default}
                      />
                    </span>
                    Active Bookings 50%
                  </p>
                  <p>
                    <span>
                      <img
                        src={
                          require("../Assets/Images/light-green.svg").default
                        }
                      />
                    </span>
                    Cancelled Bookings 50%
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="dash-bottom mb-4 mt-4">
            <Col xxl={12} xl={12} lg={12} className="mb-4">
              <div className="dash-graph">
                <h6>Total Users</h6>
                <p>
                  <span></span>Yearly Earnings Overview
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <div className="made-with">
          <div className="pixinvent">
            <p>
              © 2024, Made with ❤️ by <span>Codobux</span>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
}
