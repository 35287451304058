import React, { useState, useEffect } from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";

const COLORS = ["#FFAC33", "#6CEAC0", "#3E3C8D", "#FF6347"]; // Define colors for the chart

const CategoryChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Static data for testing
    const staticData = [
      { label: "Category A", count: 400 },
      { label: "Category B", count: 300 },
      { label: "Category C", count: 300 },
      { label: "Category D", count: 200 },
    ];

    // Prepare the data for Recharts
    const formattedData = staticData.map((item) => ({
      name: item.label, // "label" now refers to the name of the category
      value: item.count, // "count" remains the value for the pie slices
    }));
    setData(formattedData);
  }, []);

  // Calculate the total value for displaying in the center
  const totalValue = data.reduce((sum, entry) => sum + entry.value, 0);

  // Reusable renderLegend function now embedded inside CategoryChart
  const renderLegend = (data, colors, title) => {
    return (
      <div
        style={{
          listStyleType: "none",
          margin: 0,
          textAlign: "left",
          width: "50%",
          padding: "25px",
        }}
      >
        {title && (
          <h6
            style={{
              display: "flex",
              fontSize: "25px",
              fontWeight: 700,
              lineHeight: "20px",
              marginBottom: "20px",
            }}
          >
            {title}
          </h6>
        )}
        <ul style={{ padding: 0 }}>
          {data.map((entry, index) => (
            <li
              key={`item-${index}`}
              style={{
                color: colors[index % colors.length],
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: colors[index % colors.length],
                  marginRight: "8px",
                }}
              ></span>
              {entry.name}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer>
        <PieChart>
          <Tooltip />
          <Pie
            data={data}
            dataKey="value" // Field to extract the donut slice size
            nameKey="name" // Field for the slice label
            cx="50%" // Center X-axis
            cy="50%" // Center Y-axis
            innerRadius={110} // Inner radius for the donut hole
            outerRadius={150} // Outer radius for the size of the chart
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          {/* Add centered text */}
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total: {totalValue}
          </text>
        </PieChart>
      </ResponsiveContainer>

      {/* Using the embedded renderLegend function */}
      {/* {renderLegend(data, COLORS, "Top Categories")} */}
    </div>
  );
};

export default CategoryChart;
