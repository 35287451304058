import React, { useEffect } from "react";
import Table from "react-bootstrap/Table";
import Tab from "react-bootstrap/Tab";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import PaginationComponet from "../Components/Layout/Pagination";
import { useDispatch } from "react-redux";
import { usermanagement } from "../Redux/Actions/AdminActions";

export const PropertyOwner = ({
  handlepagechnage,
  Userdata,
  currentPage,
  limit,
  activeTab,
}) => {
  const userdata = Userdata;
  const handlePageChange = handlepagechnage;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //  dispatch(usermanagement({type:1}))
  // },[])

  return (
    <div class="table-responsive">
      <Table size="sm" className="table-cmn">
        <thead>
          <tr>
            <th>S.no.</th>
            <th>Property ID</th>
            <th>Property Name</th>
            <th>Owner Name</th>
            <th>Email</th>
            <th>Contact</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {userdata?.data?.users?.map((res, index) => {
            const serialNumber =
              ((currentPage == "1" ? 1 : currentPage) - 1) * limit + index + 1;
            return (
              <tr key={index}>
                <td>{serialNumber}</td>
                <td>
                  <div className="">
                    <div className="user-id">
                      <p>{res?.uniqueId || "N/A"}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <div className="user-role">
                    <p>{res?.hotel_name || "N/A"}</p>
                  </div>
                </td>
                <td>
                  <div className="first-user">
                    <div className="user-profile">
                      <div className="user-id">
                        <p>
                          {res?.userId?.first_name && res?.userId?.last_name
                            ? `${res?.userId?.first_name} ${res?.userId?.last_name} `
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>

                <td>
                  <div className="user-role">
                    <p>{res?.desk_address || "N/A"}</p>
                  </div>
                </td>
                <td>
                  <div className="cont-num">
                    {" "}
                    {res?.desk_phone_number
                      ? res.country_code
                        ? `${res.country_code} ${res.desk_phone_number}`
                        : `${res.desk_phone_number}`
                      : "N/A"}{" "}
                  </div>
                </td>
                <td>
                  <div
                    className={`${res?.is_active === 1 ? "active" : "pending"}`}
                  >
                    <p>{res?.is_active === 1 ? "Active" : "Suspended"}</p>
                  </div>
                </td>

                <td>
                  <div
                    className="pending view-green"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      navigate(`/property-owners-ovreview/${res?._id}`)
                    }
                  >
                    <p>View</p>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="pagination-section">
        <div className="showing-user">
          <p>
            {" "}
            Showing {1 + (currentPage - 1) * limit} -{" "}
            {Math.min(1 * limit, userdata?.data?.totalCount)} of{" "}
            {userdata?.data?.totalCount} results
          </p>
        </div>
        <div className="pagination-block">
          <PaginationComponet
            currentPage={currentPage}
            totalCount={userdata?.data?.totalCount}
            limit={Math.ceil(userdata?.data?.totalCount / limit)}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyOwner;
